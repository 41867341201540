@import "../../styles/mixins.scss";

.HeadingLink {
  display: none;
  flex-direction: row;
  justify-content: center;
  padding: 1.2rem;

  @include desktop {
    display: flex;
  }

  a {
    text-decoration: underline;
    font-weight: bold;
    font-size: 2rem;
  }
}

.FindSpaceHeading {
  display: none;

  @include desktop {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.SpaceHero {
  position: relative;
  margin-top: 2rem;
  margin-bottom: 0;

  @include desktop {
    margin-top: 0;
    margin-bottom: rem(70);
    padding: 0;
    width: 37.5rem;
    height: 30rem;
  }
}

.BrowseSpaces {
  margin-top: rem(54);
  margin-bottom: rem(68);

  h3 {
    @include font-normal;
    @include font-bold;

    @include desktop {
      font-size: rem(20);
      line-height: rem(20);
      margin-bottom: rem(-10);
      margin-top: 0;
    }
  }
}

.SolutionsList {
  @include desktop {
    margin-top: rem(-15);
  }
}

.ViewAll {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @include desktop {
    justify-content: flex-start;
    margin-top: rem(-17);
  }
}
