@import "../../styles/mixins.scss";

.Heading {
  margin: 1.2rem 0;
  text-decoration: underline;
  text-decoration-thickness: rem(2);
  @include font-bold;
  @include font-heading-small;

  @include desktop {
    @include font-heading;
  }
}

.Small {
  font-size: rem(20);
}
