@import "../../styles/mixins.scss";

.NewsPostItem {
  margin-bottom: 1.5rem;
}

.NewsPostItemMedium {
  .Image {
    @include desktop {
      width: 24.75rem;
      height: 17.6875rem;
    }
  }

  .Category {
    width: rem(86);
    text-align: left;

    @include desktop {
      margin-right: rem(26);
    }
  }

  .Title {
    @include font-demi;
    @include font-normal;
    margin-bottom: rem(4);
    text-decoration: none;
    text-align: left;

    @include desktop {
      @include font-paragraph;
      margin-bottom: rem(4);
    }
  }

  @include desktop {
    width: 24.75rem;

    &:hover {
      .Title {
        text-decoration: underline;
      }
    }

    .Title {
      text-decoration: none;
    }
  }
}

.Image {
  position: relative;

  @include desktop {
    position: relative;
    width: 37.5rem;
    height: 30rem;
    padding: 0;
  }
}

.PostInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  padding: rem(6) 0;

  @include desktop {
    padding: rem(16) 0;
  }
}

.Category {
  @include font-book;
  line-height: 1rem;
  font-size: rem(14);
  width: rem(81);

  @include desktop {
    font-size: rem(17);
    width: rem(116);
  }
}

.Excerpt {
  flex: 2;
}

.Title {
  @include font-demi;
  @include font-paragraph;
  text-decoration: underline;

  @include desktop {
    @include font-paragraph;
    font-size: rem(20);
  }
}

.Byline {
  @include font-book;
  font-size: rem(14);
  margin-top: rem(4);

  @include desktop {
    font-size: rem(17);
    margin-top: rem(2);
  }
}
