@import "../../styles/mixins.scss";

.NewsContinuedContainer {
  padding: 0 1rem;
  @include desktop {
    padding: 0 rem(16);
  }
}

.NewsContinued {
  display: none;

  @include desktop {
    display: grid;
    gap: 1.2rem;
    grid-template-columns: repeat(5, minmax(auto, 1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(10rem, 1fr);
    overflow-x: auto;
    margin: 3rem auto 5rem auto;
  }
}

.NewsContinuedMobile {
  display: block;
  margin: rem(62) 0;

  @include desktop {
    display: none;
    margin: 0;
  }

  h3 {
    @include font-normal;
    @include font-bold;
    margin-bottom: rem(16);

    @include desktop {
      @include font-mini;
      margin-bottom: rem(6);
    }
  }
}

.Continue {
  display: none;
  @include desktop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
    padding: 0.8rem 0;

    font-size: 1.2rem;
  }
}

.Post {
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  padding: 0.8rem 0;

  .Image {
    position: relative;
    width: rem(77);
    height: rem(111);
    margin-right: 1.25rem;
  }

  .Info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 300;
    font-size: 0.8rem;
  }

  .Title {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2rem;
  }
}
