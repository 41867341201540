@import "../../styles/mixins.scss";

.CalloutSection {
  .Wrapper {
    display: flex;
    flex-direction: column;
    padding: rem(16);

    @include desktop {
      flex-direction: row;
      padding: 0 rem(16);
    }
  }

  h2 {
    @include font-heading;
    @include font-medium;

    @include desktop {
      @include font-heading-large;
    }
  }
}

.DesktopImage {
  position: relative;
  display: none;
  width: 45%;
  margin-right: 2rem;

  @include desktop {
    display: block;
  }
}

.MobileImage {
  display: block;
  position: relative;
  margin: 2rem 0;

  width: 100%;
  height: 50vw;

  @include desktop {
    display: none;
  }
}

.Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  flex: 1;

  p {
    @include font-book;
    @include font-paragraph-large;
    margin: 1rem 0 3rem 0;

    @include desktop {
      margin: 1rem 0;
    }
  }

  @include desktop {
    text-align: left;
    align-items: flex-start;
    margin-left: 2rem;
  }
}
