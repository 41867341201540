@import "../styles/mixins/media.scss";

.WorkspacesBanner {
  position: relative;
  width: 100%;
  margin: rem(39) auto 0 auto;

  @include desktop {
    max-width: rem(720);
    margin: rem(70) auto rem(55) auto;
  }
}

.WorkspacesTitle {
  font-family: "EastroomWorkspaces";
  text-align: center;
  font-size: 16vw;
  line-height: 16vw;

  @include desktop {
    font-size: rem(127);
    line-height: rem(100);
  }
}

.Columns {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @include desktop {
    flex-direction: row;
  }
}

.Column {
  flex: 1;
  padding: 0 1rem;

  @include desktop {
    margin: 0 auto 2rem auto;
    padding: 0;
    &:first-child {
      margin-right: 1rem;
    }

    &:last-child {
      margin-left: 1rem;
    }
  }
}

.MeetOurClientsWrapper {
  margin-top: rem(60);

  @include desktop {
    margin-top: rem(120);
  }
}

.PreleasingWrapper {
  margin: rem(100) 0;

  @include desktop {
    margin-top: rem(163);
    margin-bottom: rem(231);
  }
}
