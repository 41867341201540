@import "../../styles/mixins.scss";

.VideoSection {
  position: relative;
  overflow: hidden;
  margin-bottom: rem(42);

  @include desktop {
    width: 100%;
    height: 42.5rem;
    margin-bottom: 0;

    &.Watching {
      height: auto;
    }
  }
}

.VideoContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
  height: rem(650);
  transition: height 200ms linear;

  @include desktop {
    height: 47vw;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: white;
  }

  &.Watching {
    position: relative;
    height: 56.25vw;
    width: 100%;

    @include desktop {
      min-height: 480px;
    }

    video {
      object-fit: contain;
      height: 100%;
      width: 100%;
      background: white;
    }
  }
}

.VideoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0.4;
}

.Watching {
  .Poster {
    padding: 0;
  }
}

.Poster {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  padding: 6rem 2rem;
  height: 100%;

  h2 {
    @include font-heading-small;
    @include font-medium;
    position: relative;
    z-index: 1;
    text-decoration: underline;
    text-align: center;

    @include desktop {
      @include font-heading;
    }
  }

  p {
    position: relative;
    z-index: 1;
    margin: 2rem 0 10rem 0;

    @include desktop {
      width: 62%;
    }
  }

  .PlayButton {
    position: relative;
    z-index: 1;
    margin: 5rem 0;
    &:hover {
      opacity: 0.8;
    }
  }
}

.Description {
  display: block;

  @include desktop {
    display: none;
  }
}

.YoutubeEmbed {
  aspect-ratio: 16/9;
  width: 100%;

  @include desktop {
    max-height: 47vw;
  }
}
